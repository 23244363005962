/* Base styles for the card */
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0; /* Remove default padding */
  margin: 0;  /* Remove default margin */
}

/* Header styling */
.card-header {
  padding: 0; /* No padding by default */
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

/* Content area styling */
.card-content {
  flex: 1;
  padding: 0; /* Remove padding from content area */
  overflow: auto;
}

/* Footer styling */
.card-footer {
  padding: 0; /* Remove default padding */
  box-sizing: border-box;
}
